import React from "react";
import styled from "styled-components";
import { getIcon } from "../../../../utils/iconContext";
import Descriptions from "./components/Descriptions";
import Properties from "./components/Properties";
import SnomedProperties from "./components/SnomedCTProperties";

const Header = styled.div`
  margin-bottom: 10px;
  padding-left: 15px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
`;

const Icon = styled.img`
  margin-right: 5px;
`;
const Title = styled.span`
  font-size: 1.4em;
`;

const Box = styled.div`
  margin-bottom: ${props => props.marginBottom};
  padding: 0;
  background-color: #fcfcfc;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  padding-left: 15px;
`;
const BoxTitle = styled.p`
  font-size: 1.1em;
  margin-bottom: 10px;
  padding: 5px 0 5px 0;
  border-bottom: 1px solid #dbdbdb;
`;

const renderMapTargetId = members => {
  const snodentMembers = members.filter(
    member => member.referenceSetId === "121000219104" && member.active
  );
  if (snodentMembers.lenght === 0) {
    return <React.Fragment />;
  } else {
    return snodentMembers.map(member => {
      return (
        <div key={member.mapTargetId}>
          <span>SNODENT ID: {member.mapTargetId}</span>
        </div>
      );
    });
  }
};

export default ({ concept, ...rest }) => (
  <div>
    <Header>
      <TitleContainer>
        <Icon src={getIcon(concept.iconId)} alt="" />
        <Title>{concept.fsn.term}</Title>
      </TitleContainer>
      {renderMapTargetId(concept.members)}
    </Header>
    <Box marginBottom={"10px"}>
      <BoxTitle>Descriptions</BoxTitle>
      <Descriptions concept={concept} />
    </Box>
    <Box marginBottom={"10px"}>
      <BoxTitle>Properties</BoxTitle>
      <Properties concept={concept} {...rest} />
    </Box>
    <Box marginBottom={"15px"}>
      <BoxTitle>SNOMED CT Properties</BoxTitle>
      <SnomedProperties concept={concept} {...rest} />
    </Box>
  </div>
);
