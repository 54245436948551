import { ApolloClient, InMemoryCache } from "apollo-boost";
import { BatchHttpLink } from "apollo-link-batch-http";
import fetch from "unfetch"

const cache = new InMemoryCache({
  cacheRedirects: {
    Query: {
      concepts: (_, args, { getCacheKey }) =>
        getCacheKey({ __typename: "Concept", id: args.id })
    }
  }
});

const client = new ApolloClient({
  link: new BatchHttpLink({
    fetch: fetch,
    uri: process.env.REACT_APP_API_ENDPOINT,
    headers: {
      Authorization: `Basic ${process.env.REACT_APP_AUTH_TOKEN}`
    },
    credentials: "omit"
  }),
  cache: cache
});

export default client;
