import React from "react"
import InfiniteScroll from 'react-infinite-scroller';
import { Query } from "react-apollo";
import styled from "styled-components"
import gql from "graphql-tag";
import { parseEffectivetime } from "../../utils/snomedUtils"
import { withRouter } from "react-router";
import queryString from "query-string";
import ImagedText from "../misc/ImagedText"
import ErrorComponent from "../misc/ErrorComponent"
import DebouncedInput from "../misc/DebouncedInput"
import LoadingIndicator from "../misc/LoadingIndicator"
import {
  List,
  Tag,
  Input,
  Icon,
  Button,
  Card,
  Dropdown,
  Menu
} from "antd";
import {
  ID_ICON,
  EFFECTIVE_TIME_ICON,
  DEFINED_ICON,
  PRIMITIVE_ICON,
  ACTIVE_ICON,
  INACTIVE_ICON
} from "../../constants/ImageConstants";

const CONCEPT_ADVANCED_SEARCH = gql`
  query CONCEPT_ADVANCED_SEARCH($branchPath: String, $ecl: String, $term: String, $ql: String, $acceptLanguage: String, $searchAfter: String, $limit: Int, $sort: [String]) {
    concepts(branchPath: $branchPath, term: $term, ecl: $ecl, ql: $ql, acceptLanguage: $acceptLanguage, searchAfter: $searchAfter, limit: $limit, sort:$sort) {
      items {
        id
        iconId
        module {
          id
          iconId
          pt {
            id
            term
          }
        }
        definitionStatus {
          id
          pt {
            id
            term
          }
        }
        effectiveTime
        pt {
          id
          term
        }
        active
      }
      total
      searchAfter 
    }
  }
`

const SORT_FIELDS = (() => {
  const sortFields = new Map();
  sortFields.set("term:asc", "Term: Ascending")
  sortFields.set("term:desc", "Term: Descending")
  sortFields.set("effectiveTime:asc", "Effective time: Ascending")
  sortFields.set("effectiveTime:desc", "Effective Time: Descending")
  sortFields.set("moduleId:asc", "Module: Ascending")
  sortFields.set("moduleId:desc", "Module: Descending")
  return sortFields
})()

const StyledCard = styled(Card)`
  &:hover {
    border: ${props => `1px solid ${props.theme.colors.primary}`};
    box-shadow: rgba(0, 0, 0, 0.41) 9px 10px 24px -9px;
    cursor: pointer;
  }
`

const DropdownPreFix = styled.span`
  color: #888;
`

const DropdownText = styled.span`
  padding: 0 5px; 
  color: #333;
  font-weight: 400;
  &:hover {
    cursor: pointer
  }
`

const FlexContainer = styled.div`
  display: flex;
`

const StyledBar = styled(FlexContainer)`
  margin: 5px 0 20px 0;
  padding: 15px 0;
  border-top: 1px solid #bdbdbd;
  border-bottom: 1px solid #bdbdbd;
`

const SubmitContainer = styled(FlexContainer)`
  padding: 10px 0;
  justify-content: flex-end;
`

const SearchContainer = styled(FlexContainer)`
  flex-direction: column;
`

const ResultContainer = styled(FlexContainer)`
  height: 100%;
  flex-direction: column;
  flex: 5;
  border-radius: 4px;
  padding: 10px 0 10px 10px;
  background-color: #ffffff;
`

const Pane = styled(FlexContainer)`
  height: 98%;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  background-color: #f5f5f5;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  margin: 10px 10px 0 10px;
  padding: 10px;
`

const Scrollable = styled.div`
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
`

const Chevron = () => (
  <Icon style={{ fontSize: "8px", padding: "0 4px" }} type="down" />
)

const PageSizeMenu = ({ total, onClick }) => (
  <Menu onClick={onClick}>
    <Menu.Item key={50}>
      50
    </Menu.Item>
    <Menu.Item key={100}>
      100
    </Menu.Item>
    <Menu.Item key={200}>
      200
    </Menu.Item>
    <Menu.Item key={400}>
      400
    </Menu.Item>
    <Menu.Item key={800}>
      800
    </Menu.Item>
    <Menu.Item key={total}>
      All
    </Menu.Item>
  </Menu>
)

const SortByMenu = ({ onClick }) => (
  <Menu onClick={onClick}>
    {Array.from(SORT_FIELDS).map(([key, value]) => <Menu.Item key={key}>{value}</Menu.Item>)}
  </Menu>
)

const PageSizeSelector = ({ total, pageSize, onPageSizeChange }) => (
  <div >
    <DropdownPreFix>Page size:</DropdownPreFix>
    <Dropdown trigger={["click"]} overlay={() => <PageSizeMenu total={total} onClick={onPageSizeChange} />}>
      <DropdownText>
        {pageSize === total ? "All" : pageSize}
        <Chevron />
      </DropdownText>
    </Dropdown>
  </div>
)

const SortBySelector = ({ sortField, onSortByChange }) => (
  <div>
    <DropdownPreFix>Sort by:</DropdownPreFix>
    <Dropdown trigger={["click"]} overlay={() => <SortByMenu onClick={onSortByChange} />}>
      <DropdownText>
        {SORT_FIELDS.get(sortField)}
        <Chevron />
      </DropdownText>
    </Dropdown>
  </div>
)

const Results = ({ history, term, query, onLoadMore, onFilter, pageSize, sortField, onPageSizeChange, onSortByChange }) => {
  if (query) {
    return (
      <Query
        query={CONCEPT_ADVANCED_SEARCH}
        variables={{
          branchPath: window.config.snomed.branchPath,
          ql: query,
          ecl: window.config.snomed.refsetFilter === "" ? undefined : window.config.snomed.refsetFilter,
          limit: pageSize,
          term: term === "" ? undefined : term,
          acceptLanguage: window.config.snomed.acceptLanguages,
          sort: sortField
        }}
      >
        {({ loading, error, data, fetchMore }) => {
          if (loading) {
            return (
              <LoadingIndicator
                centered
                iconStyles={{
                  color: window.config.theme.colors.primary,
                  fontSize: "50px"
                }}
                textStyles={{
                  fontSize: "18px",
                  fontWeight: 300,
                  padding: "5px"
                }}
              />
            )
          } else if (error) {
            return <ErrorComponent errorMessage={error.message} />
          } else {
            const concepts = data.concepts
            return (
              <React.Fragment>
                <StyledBar>
                  <Tag>Results: {concepts.items.length} from {concepts.total} </Tag>
                  <div style={{ flexBasis: "45%" }}>
                    <DebouncedInput
                      defaultValue={term}
                      placeholder="Filter results by term or id..."
                      onChange={onFilter}
                    />
                  </div>
                  <div style={{ display: "flex", flex: 1, justifyContent: "flex-end", marginLeft: "10px" }}>
                    <PageSizeSelector
                      total={concepts.total}
                      pageSize={pageSize}
                      onPageSizeChange={onPageSizeChange}
                    />
                    <SortBySelector
                      sortField={sortField}
                      onSortByChange={onSortByChange}
                    />
                  </div>
                </StyledBar>
                <Scrollable>
                  <ScrollableResultSet
                    history={history}
                    data={concepts}
                    onLoadMore={() => onLoadMore(concepts.searchAfter, fetchMore)}
                  />
                </Scrollable>
              </React.Fragment>
            )
          }
        }}
      </Query>
    )
  } else {
    return <React.Fragment />
  }
}

const ScrollableResultSet = ({ history, data, onLoadMore }) => {
  const concepts = data.items
  return (
    <InfiniteScroll
      hasMore={concepts.length < data.total}
      initialLoad={false}
      pageStart={1}
      loadMore={onLoadMore}
      loader={
        <LoadingIndicator
          key="loadingIndicator"
          centered
          showText={false}
          iconStyles={{ fontSize: "40px", margin: "5px", color: window.config.theme.colors.primary }}
        />
      }
      useWindow={false}
    >
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: data.total >= 2 ? 2 : 1,
          lg: data.total >= 2 ? 2 : 1,
          xl: data.total >= 2 ? 2 : 1,
          xxl: data.total >= 4 ? 4 : 1,
        }}
        key="list"
        dataSource={concepts}
        renderItem={item => <Result history={history} key={item.id} concept={item} />}
      >
      </List>
    </InfiniteScroll>
  )
}

const Result = ({ history, concept }) => {
  return (
    <List.Item>
      <StyledCard
        onClick={e => history.push({ pathname: history.location.pathname, search: queryString.stringify({ view: "browse", component: concept.id }) })}
        bordered={true}
        size="small"
        title={<ImagedText text={concept.pt.term} icon={concept.iconId} />}
      >
        <FlexContainer style={{ flexDirection: "column" }}>
          <ImagedText
            text={concept.id}
            icon={ID_ICON}
          />
          <ImagedText text={parseEffectivetime(concept.effectiveTime)} icon={EFFECTIVE_TIME_ICON} />
          <ImagedText text={concept.module.pt.term} icon={concept.module.iconId} />
          <ImagedText text={concept.definitionStatus.pt.term} icon={concept.definitionStatus.pt.term === "Defined" ? DEFINED_ICON : PRIMITIVE_ICON} />
          <ImagedText text={concept.active ? "Active" : "Inactive"} icon={concept.active ? ACTIVE_ICON : INACTIVE_ICON} />
        </FlexContainer>
      </StyledCard>
    </List.Item>
  )
}

class SearchPage extends React.Component {

  state = {
    pageSize: 100,
    filterText: ""
  }

  getQuery = () => {
    const query = this.extractQueryParams().query
    return query ? query.replace(/\\n/g, "\\n") : undefined
  }

  getSortField = () => {
    const queryParams = this.extractQueryParams()
    // set term sort by default
    return queryParams.sort ? queryParams.sort : "term:asc"
  }

  onSortChange = (e) => {
    const sortField = e.key
    const newQueryParams = Object.assign(this.extractQueryParams(), {
      sort: [sortField]
    })
    this.changeQueryParams(newQueryParams)
  }

  onSearchRun = () => {
    const query = (document.getElementById("queryInput").value)
    if (query && query.length !== 0) {
      const newQueryParams = Object.assign(this.extractQueryParams(), {
        query: query
      })
      this.changeQueryParams(newQueryParams)
    }
  }

  changeQueryParams = (queryParams) => {
    this.props.history.push({ to: this.props.history.location.pathname, search: queryString.stringify(queryParams) })
  }

  extractQueryParams = () => {
    return queryString.parse(this.props.history.location.search)
  }

  onFilter = (text, e) => {
    this.setState({ filterText: text })
  }

  isFiltered = () => {
    return this.state.filterText || this.state.filterText !== ""
  }

  onPageSizeChange = ({ key: size }) => {
    this.setState({
      pageSize: parseInt(size)
    })
  }

  onLoadMore = (searchAfter, fetchMore) => {
    fetchMore({
      variables: {
        ql: this.getQuery(),
        limit: this.state.pageSize,
        ecl: window.config.snomed.refsetFilter === "" ? undefined : window.config.snomed.refsetFilter,
        acceptLanguage: window.config.snomed.acceptLanguages,
        searchAfter: searchAfter
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev
        }
        return {
          ...prev,
          concepts: {
            ...fetchMoreResult.concepts,
            items: [...prev.concepts.items, ...fetchMoreResult.concepts.items]
          }
        }
      }
    })
  }

  render() {
    const query = this.getQuery()
    return (
      <Pane>
        <SearchContainer>
          <Input.TextArea
            defaultValue={query ? query : ""}
            id="queryInput"
            onChange={this.onSearch}
            type="textArea"
            autosize={{ minRows: 6 }}
            placeholder="Use query language to search concepts..." />
          <SubmitContainer>
            <Button type="ghost" onClick={this.onSearchRun} icon="search">Run</Button>
          </SubmitContainer>
        </SearchContainer>
        <ResultContainer>
          <Results
            history={this.props.history}
            term={this.state.filterText}
            query={query}
            onFilter={this.onFilter}
            onLoadMore={this.onLoadMore}
            pageSize={this.state.pageSize}
            sortField={this.getSortField()}
            onPageSizeChange={this.onPageSizeChange}
            onSortByChange={this.onSortChange}
          />
        </ResultContainer>
      </Pane>
    )
  }

}

export default withRouter(SearchPage)