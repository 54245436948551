import React from "react";
import styled from "styled-components";
import { Icon as AntdIcon} from "antd"

const config = window.config;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
`;
const Header = styled.header`
  display: flex;
  flex: 0 0 auto;
  height: 55px;
  background-color: ${props => props.theme.colors.primary};
`;
const Content = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
`;

const Footer = styled.footer`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60px;
  background-color: ${props => props.theme.colors.secondary};
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0 5px 20px;
`;

const TransparentButton = styled.button`
  outline: none;
  color: black;
  background-color: ${props => props.theme.colors.primary};
  border: none;
  font-size: 20px;
  &:hover {
    cursor: pointer;
  }
`

const Icon = ({ src = config.brand.icon, height = 50 }) => (
  <IconContainer>
    <img src={src} height={height} alt=""/>
  </IconContainer>
);

const HamburgerIcon = ({ onClick }) => (
  <IconContainer>
    <TransparentButton onClick={onClick}>
      <AntdIcon type="menu"/>
    </TransparentButton>
  </IconContainer>
)

const CopyrightNotice = () => (
  <div style={{ color: config.theme.colors.textSecondary }}>
    {config.commons.copyrightNotice}
  </div>
);

export { Container, Header, Icon, Content, Footer, CopyrightNotice, HamburgerIcon };
