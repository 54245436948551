import React from "react";
import _ from "lodash"
import { getIcon } from "../../utils/iconContext"
import queryString from "query-string";
import gql from "graphql-tag";
import styled from "styled-components";
import BrowseTree from "./BrowseTree";
import FilteredTree from "./FilteredTree";
import { Input } from "antd";
import { Query } from "react-apollo";
import { withRouter } from "react-router";
import ErrorComponent from "../misc/ErrorComponent"
import Tooltip from "../misc/Tooltip"
import LoadingIndicator from "../misc/LoadingIndicator"
import {
  ID_ICON,
  FULLY_SPECIFIED_NAME_ICON,
  CHILDREN_NUMBER_ICON
} from "../../constants/ImageConstants"

const CONCEPT_DESCENDANTS_TOTAL_QUERY = gql`
  query conceptDescendantsTotal($branchPath: String, $ecl: String) {
    concepts(branchPath: $branchPath, ecl: $ecl, limit: 0, active: true) {
      total
    }
  }
`

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 0 5px 0 5px;
`;

const TreeContainer = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

const Toolbar = styled.div`
  display: block;
`;

const SearchContainer = styled.div`
  display: flex;
  padding: 5px 0 5px 0;
  justify-content: flex-start;
`;

const Row = styled.div`
  display: flex;
  padding-bottom: 2px;
`

const Column = styled.div`
  display: flex;
`

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ImagedTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.span`
  padding-left: 5px;
  font-weight: ${props => props.weight};
`

const ImagedText = ({ text, img, weight = "normal" }) => (
  <ImagedTextContainer>
    <img src={getIcon(img)} alt="" />
    <Text weight={weight} >{text}</Text>
  </ImagedTextContainer>
);

const TooltipContent = ({ concept }) => {
  return (
    <TooltipContainer>
      <Row>
        <Column>
          <ImagedText text="ID:" img={ID_ICON} weight={600} />
          <Text>{concept.id}</Text>
        </Column>
      </Row>
      <Row>
        <Column>
          <ImagedText text="Fully specified name:" img={FULLY_SPECIFIED_NAME_ICON} weight={600} />
          <Text>{concept.fsn.term}</Text>
        </Column>
      </Row>
      {window.config.snomed.showModulesInTooltips &&
        (
          <Row>
            <Column>
              <ImagedText text="Module:" img={concept.module.iconId} weight={600} />
              <Text>{concept.module.pt.term}</Text>
            </Column>
          </Row>
        )
      }
      
      <Row>
        <Column>
          <ImagedText text="Descendants:" img={CHILDREN_NUMBER_ICON} weight={600} />
          <Query
            query={CONCEPT_DESCENDANTS_TOTAL_QUERY}
            variables={buildDescendantsQueryVars(concept.id)}
          >
            {({ loading, error, data }) => {
              if (loading) {
                return (
                  <Text>
                    <LoadingIndicator
                      iconStyles={{
                        color: window.config.theme.colors.primary,
                        position: "relative",
                        top: "2px",
                        fontSize: "15px"
                      }}
                      textStyles={{
                        fontSize: "14px",
                        fontWeight: 300,
                        padding: "0 5px"
                      }}
                    />
                  </Text>
                )
              } else if (error) {
                return <Text>Error.. {error.message}</Text>
              } else {
                return <Text>{new Intl.NumberFormat("us-US").format(data.concepts.total)}</Text>
              }
            }}
          </Query>

        </Column>
      </Row>
    </TooltipContainer>
  )
}

const buildDescendantsQueryVars = (conceptId) => {
  const snomedConfig = window.config.snomed;
  const vars = {
    branchPath: snomedConfig.branchPath
  }
  if (snomedConfig.refsetFilter === "") {
      vars['ecl'] = `<${conceptId}`
  } else {
    vars['ecl'] = `<${conceptId} AND ${snomedConfig.refsetFilter}`
  }
  return vars;
}

class TreeView extends React.Component {

  constructor(props) {
    super(props)
    this.delayedCallback = _.debounce(this.onDebounceFilterTextChange, 1000)
  }

  state = {
    error: null
  }

  onTreeSelection = id => {
    const { history } = this.props;
    const existingSearchParams = this.extractSearchParams();
    const newSearchParams = Object.assign(existingSearchParams, {
      component: id
    });
    history.push({
      path: "/",
      search: queryString.stringify(newSearchParams)
    });
  };

  onChange = (event) => {
    event.persist()
    this.delayedCallback(event)
  }

  onDebounceFilterTextChange = event => {
    const value = event.target.value
    const { history } = this.props;
    const existingSearchParams = this.extractSearchParams();
    const newSearchParams = Object.assign(existingSearchParams, {
      term: value.trim()
    });

    if (!value || !value.trim() || value.length <= 2) {
      delete newSearchParams.term;
    }
    history.push({
      path: "/",
      search: queryString.stringify(newSearchParams)
    });
  };

  getTermFilter = () => {
    const searchParams = this.extractSearchParams();
    if ("term" in searchParams) {
      return searchParams["term"];
    } else {
      return "";
    }
  };

  extractSearchParams = () => {
    const { location } = this.props;
    return queryString.parse(location.search);
  };

  onError = (error) => {
    this.setState({
      error: error
    })
  }

  withTooltip = (label, concept) => (
    <Tooltip title="Concept" content={<TooltipContent concept={concept} />}><span>{label}</span></Tooltip>
  )

  render() {
    const filterText = this.getTermFilter();
    const hasFilterText = filterText !== "";
    const refsetFilter = window.config.snomed.refsetFilter;
    const hasRefsetFilter = refsetFilter !== undefined && refsetFilter !== ""

    const Tree = (hasFilterText || hasRefsetFilter) ? FilteredTree : BrowseTree;
    return (
      <Container>
        {
          this.state.error ? <ErrorComponent errorMessage={this.state.error.message} /> :
            <React.Fragment>
              <Toolbar>
                <SearchContainer>
                  <Input.Search
                    size="small"
                    placeholder="Type to filter concepts..."
                    allowClear
                    defaultValue={filterText}
                    onChange={this.onChange}
                  />
                </SearchContainer>
              </Toolbar>
              <TreeContainer>
                <Tree withTooltip={this.withTooltip} onError={this.onError} filterText={filterText} onTreeSelection={this.onTreeSelection} />
              </TreeContainer>
            </React.Fragment>
        }
      </Container>
    );
  }
}

export default withRouter(TreeView);
