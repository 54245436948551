const parseEffectivetime = effectiveTime => {
  if (effectiveTime === null) {
    return "Unpublished";
  } else {
    const year = effectiveTime.slice(0, 4);
    const month = effectiveTime.slice(4, 6);
    const days = effectiveTime.slice(6, 8);
    return [year, month, days].join("-");
  }
};

export { parseEffectivetime };
