import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./utils/serviceWorker";
import { CookiesProvider } from "react-cookie"
import ReactGA from 'react-ga'
import App from './components/App'

ReactGA.initialize(window.config.commons.gaKey)

ReactDOM.render(
  <CookiesProvider>
    <App/>
  </CookiesProvider>,
  document.getElementById("root")
);

  serviceWorker.unregister();