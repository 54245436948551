import React from "react";
import styled from "styled-components";
import { getIcon } from "../../utils/iconContext"

const Icon = styled.img`
  position: relative;
  bottom: 3px;
  padding-right: 5px;
`

export default ({ text, icon, showIcon = true }) => (
  <span>
    {
      showIcon ? 
      <Icon src={getIcon(icon)} alt="" /> :
      <span style={{ height: "16px", width: "16px" }} />
    }
    {text}
  </span>
)