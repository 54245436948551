import React from "react";
import { Modal, Button } from "antd";
import PropTypes from "prop-types";

const Content = () => {
  const title = window.config.brand.title;
  return (
    <div>
      <p>
        In order to use {title}, please accept the
        following license agreement:
      </p>
      <div>
        <p>
          {title} includes SNOMED Clinical Terms® (SNOMED
          CT®) which is used by permission of SNOMED International. All
          rights reserved. SNOMED CT® was originally created by the College of
          American Pathologists. “SNOMED”, “SNOMED CT” and “SNOMED Clinical
          Terms” are registered trademarks of SNOMED International (
          <a href="http://www.snomed.org/">www.snomed.org</a>)
        </p>
        <p>
          Use of SNOMED CT in {title} is governed by the
          conditions of the following SNOMED CT license issued by SNOMED
          International:
        </p>
        <ol>
          <li>
            The meaning of the terms “Affiliate”, or “Data Analysis System”,
            “Data Creation System”, “Derivative”, “End User”, “Extension”,
            “Member”, “Non-Member Territory”, “SNOMED CT” and “SNOMED CT
            Content” are as defined in the SNOMED International Affiliate
            License Agreement (see
            <a href="http://snomed.org/license-affiliate">
              {" "}
              www.snomed.org/license-affiliate
            </a>
            ).
          </li>
          <li>
            Information about Affiliate Licensing is available at
            <a href="http://www.snomed.org/snomed-ct/get-snomed">
              {" "}
              www.snomed.org/snomed-ct/get-snomed
            </a>
            . Individuals or organizations wishing to register as SNOMED
            International Affiliates can register at mlds.ihtsdotools.org,
            subject to acceptance of the Affiliate License Agreement (see{" "}
            <a href="http://snomed.org/license-affiliate">
              {" "}
              www.snomed.org/license-affiliate
            </a>
            ).
          </li>
          <li>
            The current list of SNOMED International Member Territories can be
            viewed at{" "}
            <a href="http://www.snomed.org/our-customers/members">
              www.snomed.org/our-customers/members
            </a>
            . Countries not included in that list are "Non-Member Territories".
          </li>
          <li>
            End Users, that do not hold SNOMED International Affiliate
            License, may access SNOMED CT® using {title} {" "}
            subject to acceptance of and adherence to the following sub-license
            limitations:
            <ol type="a">
              <li>
                The sub-licensee is only permitted to access SNOMED CT® using
                this software (or service) for the purpose of exploring and
                evaluating the terminology.
              </li>
              <li>
                The sub-licensee is not permitted the use of this software as
                part of a system that constitutes a SNOMED CT "Data Creation
                System" or "Data Analysis System", as defined in the SNOMED
                International Affiliate License. This means that the
                sub-licensee must not use {title} to add or
                copy SNOMED CT identifiers into any type of record system,
                database or document.
              </li>
              <li>
                The sub-licensee is not permitted to translate or modify SNOMED
                CT Content or Derivatives
              </li>
              <li>
                The sub-licensee is not permitted to distribute or share SNOMED
                CT Content or Derivatives
              </li>
            </ol>
          </li>
          <li>
            SNOMED International Affiliates may use {title} {" "}
            as part of a "Data Creation System" or "Data Analysis System"
            subject to the following conditions:
            <ol type="a">
              <li>
                The SNOMED International Affiliate, using {" "}
                {title} must accept full responsibility for
                any reporting and fees due for use or deployment of such a
                system in a Non-Member Territory.
              </li>
              <li>
                The SNOMED International Affiliate must not use {" "}
                {title} to access or interact with SNOMED CT
                in any way that is not permitted by the Affiliate License
                Agreement.
              </li>
              <li>
                In the event of termination of the Affiliate License Agreement,
                the use of {title} will be subject to the
                End User limitations noted in 4.
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  );
};

const bodyStyle = {
  height: "500px",
  overflowY: "auto"
};

const LicenseModal = ({ visible, onAccept, onReject }) => {
  return (
    <Modal
      title={`${window.config.brand.title} License`}
      visible={visible}
      closable={false}
      bodyStyle={bodyStyle}
      footer={[
        <Button key="accept" type="primary" onClick={onAccept}>
          Accept
        </Button>,
        <Button key="reject" type="danger" onClick={onReject}>
          <a href={window.config.commons.licenseRedirect}>Reject</a>
        </Button>
      ]}
    >
      <Content />
    </Modal>
  );
};

LicenseModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired
};

export default LicenseModal;
