import React from "react";
import { Button, Alert } from "antd";

const CookieBanner = ({ onAccept }) => {
  return (
    <div>
      <Alert
        banner={true}
        showIcon={false}
        type="info"
        style={{
          color: window.config.theme.colors.textSecondary,
          backgroundColor: window.config.theme.colors.secondary
        }}
        message={
          <div>
            <span>
              We use cookies on this site to enhance your user experience.
            </span>
            <Button
              onClick={onAccept}
              ghost={true}
              size="small"
              type="primary"
              style={{ margin: "0 10px 0 10px", color: window.config.theme.colors.primary, borderColor: window.config.theme.colors.primary }}
            >
              Accept
            </Button>
          </div>
        }
      />
    </div>
  );
};

export default CookieBanner;
