import React from 'react';
import styled from 'styled-components';
import { getIcon } from '../../../../../utils/iconContext';
import { INFERRED_RELATIONSHIP, IS_A } from "../../../../../constants/SnomedConstants"
import { Link } from "react-router-dom"
import queryString from "query-string";
import { parseEffectivetime } from "../../../../../utils/snomedUtils"
import Tooltip from "../../../../misc/Tooltip";

import {
  ID_ICON,
  EFFECTIVE_TIME_ICON,
  CALCULATOR_ICON,
} from  "../../../../../constants/ImageConstants";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  padding-bottom: 2px;
`

const Column = styled.div`
  display: flex;
  flex-basis: ${props => props.basis};
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${props => props.paddingBottom};
`

const ImagedTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.span`
  padding-left: 5px;
  font-weight: ${props => props.weight};
`

const ImagedText = ({ text, img, weight = "normal" }) => (
  <ImagedTextContainer>
    <img src={getIcon(img)} alt="" />
    <Text weight={weight} >{text}</Text>
  </ImagedTextContainer>
);

const ImagedLink = ({ id, text, img, location }) => (
  <ImagedTextContainer>
    <img src={getIcon(img)} alt="" />
    <Link 
      to={getRedirectObject(id, location)}
      style={{paddingLeft: "5px"}}>{text}</Link>
  </ImagedTextContainer>
);

const TooltipContent = ({ rel }) => (
    <Box paddingBottom={0}>
      <Row>
        <Column>
          <ImagedText weight={600} text="ID:" img={ID_ICON}/>
          <Text>{rel.id}</Text>
        </Column>
      </Row>
      <Row>
        <Column>
          <ImagedText weight={600} text="Effective Time:" img={EFFECTIVE_TIME_ICON} />
          <Text>{parseEffectivetime(rel.effectiveTime)}</Text>
        </Column>
      </Row>
      <Row>
        <Column>
          <ImagedText weight={600} text="Characteristic type:" img={rel.characteristicType.iconId} />
          <Text>{rel.characteristicType.pt.term}</Text>
        </Column>
      </Row>
      <Row>
        <Column>
          <ImagedText weight={600} text="Modifier:" img={rel.modifier.iconId} />
          <Text>{rel.modifier.pt.term}</Text>
        </Column>
      </Row>
      {rel.value &&
        (
          <Row>
            <Column>
              <ImagedText weight={600} text="Value type:" img={CALCULATOR_ICON} />
              <Text>{getValueTypeLabel(rel)}</Text>
            </Column>
          </Row>
        )
      }
      {window.config.snomed.showModulesInTooltips &&
        (
          <Row>
            <Column>
              <ImagedText weight={600} text="Module:" img={rel.module.iconId} />
              <Text>{rel.module.pt.term}</Text>
            </Column>
          </Row>
        )
      }
    </Box>
)

const getRedirectObject = (id, location) => {
  const existingSearchParams = queryString.parse(location.search)
  const newSearchParam = Object.assign(existingSearchParams, {
    component: id
  })
  return {
    pathname: location.pathname,
    search: queryString.stringify(newSearchParam)
  }
}

const RelationshipGroup = ({ group, relationships, ...rest }) => {
  const title = group === "0" ? "Ungrouped Properties" : `Property group ${group}`
  return (
    <Box paddingBottom="1em">
      <div style={{ paddingBottom : "0.25em", fontWeight: "bold" }}>
        <span>{title}</span>
      </div>
        {
          relationships.sort(sortRelationships).map(rel => {
            return (
              <Row key={rel.id}>
                <Column basis="25%">
                  <ImagedText text={getRelationshipTypeLabel(rel)} img={rel.type.iconId} />
                </Column>
                <Column basis="75%">
                  <Tooltip title={rel.value ? "Relationship with value" : "Relationship" } content={<TooltipContent rel={rel} />}>
                    <div>
                      { rel.value 
                        ? <ImagedText text={getDestinationLabel(rel)} img={CALCULATOR_ICON}/>
                        : <ImagedLink id={rel.destination.id} text={getDestinationLabel(rel)} img={rel.destination.iconId} location={rest.location}/>
                      }
                    </div> 
                  </Tooltip>
                </Column>
              </Row>
            )
          })
        }
    </Box>
  )
}

const Properties = ({ concept, ...rest }) => {
  const inferredActiveRelationships = concept.relationships
    .filter(rel => rel.active && INFERRED_RELATIONSHIP === rel.characteristicType.id);
  const groupedRelatiomships = groupBy("group", inferredActiveRelationships)

  return (
    <Container>
      {Object.keys(groupedRelatiomships)
        .map(group => <RelationshipGroup key={group} group={group} relationships={groupedRelatiomships[group]} {...rest} />)
      }
    </Container>
  )
}

const sortRelationships = (rel, otherRel) => {
  const sortedByType = sortByType(rel, otherRel)
  if (sortedByType === 0) {
    return sortByDestinationTerm(rel, otherRel);
  } else {
    return sortedByType;
  }
}

const sortByType = (rel, otherRel) => {
  const relTypeId = rel.type.id
  const otherRelTypeId = otherRel.type.id
  if (relTypeId === IS_A && otherRelTypeId !== IS_A) {
    return -1
  } else if (relTypeId === IS_A && otherRelTypeId === IS_A) {
    return 0;
  } else if (relTypeId !== IS_A && otherRelTypeId === IS_A) {
    return 1
  } else {
    return getRelationshipTypeLabel(rel).localeCompare(getRelationshipTypeLabel(otherRel));
  }
}

const sortByDestinationTerm = (rel, otherRel) => {
  const relDestinationTerm = getDestinationLabel(rel)
  const otherRelDestinationTerm = getDestinationLabel(rel)
  return relDestinationTerm.localeCompare(otherRelDestinationTerm);
}

const groupBy = (key, array) => {
  return array.reduce((acc, group) => {
    (acc[group[key]] = acc[group[key]] || []).push(group);
    return acc;
  }, {});
};

const getRelationshipTypeLabel = (rel) => {
  if (rel.type.pt === null) {
    console.log(rel)
    return rel.type.fsn.term
  } else {
    return rel.type.pt.term
  }
}

const getDestinationLabel = (rel) => {
  if (rel.value) {
    return getValueLabel(rel)
  }

  if (rel.destination.pt === null) {
    return rel.destination.fsn.term
  }
  
  return rel.destination.pt.term
}

const getValueLabel = (rel) => {
  if (rel.value.type === "STRING") {
    return rel.value.stringValue
  } else {
    return "#" + rel.value.numericValue
  }
}

const getValueTypeLabel = (rel) => {
  const valueType = rel.value.type
  return valueType.charAt(0) + valueType.slice(1).toLowerCase()
}

export default Properties