import React from "react"
import PropTypes from 'prop-types';
import _ from "lodash"
import styled from "styled-components"

const StyledInput = styled.input`
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  transition: all 0.6s ease-in-out;
  line-height: 1.5;
  font-size: 14px;
  padding: 1px 7px;
  color: rgba(0, 0, 0, 0.65);
  outline: none;
  &:focus {
     ::placeholder {
      color: transparent;
    }
  }
  &:hover,&:focus {
    border-color: ${props => props.theme.colors.primary};
  }
  ::placeholder {
    color: #c9c9c9;
  }
`

class DebouncedInput extends React.Component {

  constructor(props) {
    super(props)
    this.delayedCallback = _.debounce(this.onDebounceFilterTextChange, this.props.debounceDelay)
  }

  onInputChange = (event) => {
    event.persist()
    this.delayedCallback(event)
  }

  onDebounceFilterTextChange = event => {
    const value = event.target.value
    this.props.onChange(value)
  };

  render() {
    return (
      <StyledInput
        defaultValue={this.props.defaultValue}
        placeholder={this.props.placeholder}
        onChange={this.onInputChange}
      />
    )
  }
}

DebouncedInput.defaultProps = {
  value: "",
  defaultValue: "",
  placeholder: "Placeholder",
  onChange: value => console.log(value),
  debounceDelay: 1000,

}

DebouncedInput.propTypes = {
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  debounceDelay: PropTypes.number,

}

export default DebouncedInput