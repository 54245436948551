import React from "react";
import { Table } from "antd";
import { parseEffectivetime } from "../../../../utils/snomedUtils";
import { getIcon } from "../../../../utils/iconContext";
import styled from "styled-components";
import {
  INACTIVE_ICON,
  ACTIVE_ICON,
  EFFECTIVE_TIME_ICON
} from "../../../../constants/ImageConstants";

import {
  SIMPLE_TYPE_REFSET_ID,
  QUERY_REFSET_ID,
  DESCRIPTION_TYPE_REFSET_ID,
  MODULE_DEPENDENCY_REFSET_ID,
  OWL_AXIOM_REFSET_ID,
  OWL_ONTOLOGY_REFSET_ID,
  MRCM_DOMAIN_REFSET_ID,
  MRCM_ATTRIBUTE_DOMAIN_REFSET_ID,
  MRCM_ATTRIBUTE_RANGE_REFSET_ID,
  MRCM_MODULE_REFSET_ID
} from "../../../../constants/SnomedConstants";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const ImagedTextContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: auto;
  align-items: center;
  overflow: hidden;
`;

const Text = styled.span`
  padding-left: 5px;
  white-space: nowrap;
`;

const ImagedText = ({ text, icon }) => (
  <ImagedTextContainer>
    <img src={getIcon(icon)} alt="" />
    <Text>{text}</Text>
  </ImagedTextContainer>
);

const columns = [
  {
    title: "Reference set",
    dataIndex: "referenceSet",
    key: "referenceSet",
    render: referenceSetConcept => (
      <ImagedText text={referenceSetConcept.pt.term} icon={referenceSetConcept.iconId} />
    )
  },
  {
    title: "Referenced component",
    dataIndex: "referencedComponent",
    key: "referencedComponent",
    render: referencedComponent => (
      <ImagedText
        text={referencedComponent.pt.term}
        icon={referencedComponent.iconId}
      />
    )
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: active => {
      const text = active ? "Active" : "Inactive";
      const icon = active ? ACTIVE_ICON : INACTIVE_ICON;
      return <ImagedText text={text} icon={icon} />;
    }
  },
  {
    title: "Effective time",
    dataIndex: "effectiveTime",
    key: "effectiveTime",
    render: effectiveTime => {
      return (
        <ImagedText
          text={parseEffectivetime(effectiveTime)}
          icon={EFFECTIVE_TIME_ICON}
        />
      );
    }
  },
  {
    title: "Module",
    dataIndex: "module",
    key: "module",
    render: module => <ImagedText text={module.pt.term} icon={module.iconId} />
  }
];

const ACCEPTABLE_REFSET_TYPES = [
  SIMPLE_TYPE_REFSET_ID,
  QUERY_REFSET_ID,
  DESCRIPTION_TYPE_REFSET_ID,
  MODULE_DEPENDENCY_REFSET_ID,
  OWL_AXIOM_REFSET_ID,
  OWL_ONTOLOGY_REFSET_ID,
  MRCM_DOMAIN_REFSET_ID,
  MRCM_ATTRIBUTE_DOMAIN_REFSET_ID,
  MRCM_ATTRIBUTE_RANGE_REFSET_ID,
  MRCM_MODULE_REFSET_ID
];

const mapToTableData = members =>
  members
    .filter(member => {
      if (member.referenceSetConcept.type) {
        return ACCEPTABLE_REFSET_TYPES.indexOf(member.referenceSetConcept.type.id) !== -1
      } else {
        return false
      }
    })
    .map(member => ({
      key: member.id,
      referenceSet: member.referenceSetConcept,
      referencedComponent: member.referencedComponent,
      status: member.active,
      effectiveTime: member.effectiveTime,
      module: member.module
    }));

export default ({ members }) => (
  <Container>
    <Table
      pagination={false}
      columns={columns}
      dataSource={mapToTableData(members)}
      bordered={true}
      size="small"
      style={{ backgroundColor: "white", margin: "0 0.5em" }}
      scroll={{ x: "max-content" }}
    />
  </Container>
);
