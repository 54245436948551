// Common icons
const INACTIVE_ICON = "clock--minus";
const ACTIVE_ICON = "clock--plus";
const EFFECTIVE_TIME_ICON = "effective_time_img";
const DEFINED_ICON = "light-bulb";
const PRIMITIVE_ICON = "light-bulb-off";
const NON_DISJOINT_ICON = "loose";
const MUTUALLY_DISJOINT_ICON = "exhaustive";
const EXCLAMATION_ICON = "exclamation";
const QUESTION_ICON = "question-white";
const ID_ICON = "id_img";
const LANG_CODE_ICON = "locale";
const CHILDREN_NUMBER_ICON = "edit-number";
const CALCULATOR_ICON = "calculator";

// Description type icons
const FULLY_SPECIFIED_NAME_ICON = "900000000000003001";
const SYNONYM_ICON = "900000000000013009";
const PREFERRED_ICON = "rosette";
const DESCRIPTION_TYPE_ICON = "900000000000441003";

export {
  INACTIVE_ICON,
  ACTIVE_ICON,
  EFFECTIVE_TIME_ICON,
  DEFINED_ICON,
  PRIMITIVE_ICON,
  NON_DISJOINT_ICON,
  MUTUALLY_DISJOINT_ICON,
  EXCLAMATION_ICON,
  QUESTION_ICON,
  ID_ICON,
  FULLY_SPECIFIED_NAME_ICON,
  SYNONYM_ICON,
  PREFERRED_ICON,
  DESCRIPTION_TYPE_ICON,
  LANG_CODE_ICON,
  CHILDREN_NUMBER_ICON,
  CALCULATOR_ICON
};
