import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types";
import { Icon } from "antd";

const Container = styled.div`
  display: flex;
  flex: 1;
`

const CenteredContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

const defaultLoadingTextStyle = ({
  padding: "0 5px"
})

const defaultLoadingIconStyle = ({
  color: window.config.theme.colors.primary
})

const LoadingText = ({ text, textStyles }) => (
  <span style={textStyles}>{text}</span>
)

const LoadingIndicator = (props) => {
  const { centered, text, showIcon, showText, textStyles, iconStyles } = props
  const Wrapper = centered ? CenteredContainer : Container
  return (
    <Wrapper>
      { showIcon && <Icon type="loading" style={iconStyles}/> }
      { showText && <LoadingText text={text} textStyles={textStyles} /> }
    </Wrapper>
  )
}

LoadingIndicator.defaultProps = {
  centered: false,
  text: "Loading...",
  showIcon: true,
  showText: true,
  textStyles: defaultLoadingTextStyle,
  iconStyles: defaultLoadingIconStyle
}

LoadingIndicator.propTypes = {
  centered: PropTypes.bool,
  text: PropTypes.string,
  showIcon: PropTypes.bool,
  showText: PropTypes.bool,
  textStyles: PropTypes.object,
  iconStyles: PropTypes.object
};

export default LoadingIndicator