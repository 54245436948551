import React from "react"
import styled from "styled-components"
import PropTypes from 'prop-types';

const StyledMenu = styled.ul`
  font-weight: 600;
  color: white;
  font-size: 16px;
  list-style-type: none;
  padding: 0;
  margin: 0;
`

const StyledSubMenu = styled.li`
  list-style-type: none;
  padding: 0;
  margin: 0;
`

const StyledMenuItem = styled.li`
  width: 100%;
  font-weight: 600;
  padding: 10px 0 10px 35px;
  color:  ${props => {
    return props.isMenuItemSelected(props.id) ? props.theme.colors.primary: "white"}
  };
  border-right: ${props => {
    return props.isMenuItemSelected(props.id) ? `5px solid ${props.theme.colors.primary}` : "none"
  }};
  background-color: ${props => {
    return props.isMenuItemSelected(props.id) ? props.theme.colors.highlightColor : "#303030";
  }};
  &:hover {
    color: ${props => {
      return props.theme.colors.primary}
    };
    cursor: default;
  }
`

class Menu extends React.Component {

  state = {
    selectedKey: this.props.defaultSelectedId
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.selectedKey !== nextProps.defaultSelectedId) {
      return {
        selectedKey: nextProps.defaultSelectedId
      }
    }
    return null
  }

  onMenuItemClick = (e) => {
    this.setState({ selectedKey: e.currentTarget.id })
    this.props.onSelect()
  }

  isMenuItemSelected = (id) => {
    return this.state.selectedKey === id
  }

  render() {
    const childrenWithProps = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, { isMenuItemSelected: this.isMenuItemSelected, onClick: e => this.onMenuItemClick(e)})
    })
    return (
      <StyledMenu>
        {childrenWithProps}
      </StyledMenu>
    )
  }

}

const MenuItem = ({ id, children, ...rest }) => {
  return (
    <StyledMenuItem id={id} key={id} {...rest}>
      {children}
    </StyledMenuItem>
  )
}

const SubMenu = ({ id, children, title, ...rest }) => {
  return (
    <StyledSubMenu id={id} key={id}>
      {title}
       <StyledMenu>
         {
          React.Children.map(children, child => {
            return React.cloneElement(child, { ...rest })
          })
         }
       </StyledMenu>
    </StyledSubMenu>
  )
}

Menu.propTypes = {
  onSelect: PropTypes.func,
  defaultSelectedId: PropTypes.string
}

SubMenu.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.node,
  ]).isRequired
}

MenuItem.propTypes = {
  id: PropTypes.string.isRequired,
  onSelect: PropTypes.func
}


export { Menu, MenuItem, SubMenu }
