import _ from "lodash";

const ROOT_ID = "-1";

const toMap = array => {
  const idToValueMap = new Map();
  for (var i = 0, len = array.length; i < len; i++) {
    const value = array[i]
    idToValueMap.set(value.id, value)
  }
  return idToValueMap;
};

const computeHierarchicalTree = (rootConcepts, concepts) => {
  const matchesById = toMap(concepts);
  const treeItemsById = toMap(concepts);
  const topLevelConceptsById = toMap(rootConcepts);
  const closestNotApplicableAncestors = new Map();
  const superTypes = [];

  // 1. check direct parent references and attach already applicable components to the tree
  for (const concept of concepts) {
    const parentIds = concept.parentIds;

    // select only parents that are matches or top level nodes/categories
    const validParentIds = parentIds.filter(
      parentId =>
        matchesById.has(parentId) ||
        topLevelConceptsById.has(parentId) ||
        ROOT_ID === parentId
    );

    // register all parents in supertype map
    for (const parentId of parentIds) {
      superTypes.push({ id: concept.id, parentId: parentId });
    }

    if (validParentIds.length === 0) {
      // no direct applicable parents, try to find closest applicable ancestor (match or top level)
      closestNotApplicableAncestors.set(concept.id, parentIds[0]);
    }
  }

  // 2. matches that cannot be directly attached to the tree, should be connected to their closest ancestor
  const closestAncestors = findClosestAncestors(
    closestNotApplicableAncestors,
    matchesById,
    topLevelConceptsById,
    []
  );

  for (const ancestor of closestAncestors) {
    superTypes.push(ancestor);
  }

  // 3. attach top levels until we can't attach anymore
  attachTopLevels(topLevelConceptsById, treeItemsById, superTypes);

  const nodes = superTypes
    .reduce((memo, superType) => {
      if (treeItemsById.has(superType.parentId) || superType.parentId === ROOT_ID) {
        const concept = treeItemsById.get(superType.id)
        const parent =
        superType.parentId === ROOT_ID
          ? { id: ROOT_ID }
          : treeItemsById.get(superType.parentId);
        memo.push({ concept: concept, parent: parent })
      }
      return memo
    }, []);

  return finalizeTree(nodes);
};

const findClosestAncestors = (
  closestNotApplicableAncestors,
  matchesById,
  topLevelConceptsById,
  closestAncestors
) => {
  if (closestNotApplicableAncestors.size === 0) {
    return closestAncestors;
  } else {
    const ancestors = getAncestors(matchesById, closestNotApplicableAncestors);
    const closestNotApplicableAncestorsIds = Array.from(
      closestNotApplicableAncestors.keys()
    );
    for (const conceptId of closestNotApplicableAncestorsIds) {
      const componentAncestors = ancestors.get(conceptId);
      const closestAncestor = findClosestAncestor(
        componentAncestors,
        matchesById,
        topLevelConceptsById
      );

      if (closestAncestor) {
        closestAncestors.push({ id: conceptId, parentId: closestAncestor });
        closestNotApplicableAncestors.delete(conceptId);
      } else {
        // FIXME: otherwise update the currentAncestors with the currently available ancestor, so we can go one level up and check the next
      }
    }
    return findClosestAncestors(
      closestNotApplicableAncestors,
      matchesById,
      topLevelConceptsById,
      closestAncestors
    );
  }
};

const getAncestors = (matchesById, closestAncestors) => {
  const ancestors = new Map()
  for (const closestAncestor of closestAncestors.keys()) {
    const concept = matchesById.get(closestAncestor);
    const conceptAncestors = concept.ancestorIds;
    ancestors.set(concept.id, conceptAncestors)
  }
  return ancestors;
};

const findClosestAncestor = (ancestorIds, matchesById, topLevelsById) => {
  for (const predicate of getPredicates()) {
    const result = ancestorIds.filter(id =>
      predicate(id, matchesById, topLevelsById)
    );
    if (Array.isArray(result) && result.length !== 0) {
     return _.head(result);
    }
  }
  return undefined;
};

const getPredicates = () => [
  isMatchButNotTopLevel,
  isTopLevelButNotTerminologyRoot,
  isTerminologyRoot,
  isRoot
];

const isMatchButNotTopLevel = (id, matchesById, topLevelConceptsById) => {
  return matchesById.has(id) && !topLevelConceptsById.has(id);
};

const isTopLevelButNotTerminologyRoot = (id, matchesById, topLevelConceptsById) => {
  return topLevelConceptsById.has(id) && id !== "138875005";
};

const isTerminologyRoot = (id, matchesById, topLevelConceptsById) => {
  return id === "138875005";
};

const isRoot = (id, matchesById, topLevelConceptsById) => {
  return ROOT_ID === id;
};

const attachTopLevels = (topLevelConceptsById, treeItemsById, superTypes) => {
  // Attaches top level nodes to the superType maps until we can't attach more,
  // required top level tree nodes might be in multiple levels of the hierarchy
  const topLevelIds = Array.from(topLevelConceptsById.keys())
  const missingTopLevelIds = topLevelIds.filter(
    id => superTypes.some(superType => superType.parentId === id) && !treeItemsById.has(id)
  );

  for (const missingTopLevelId of missingTopLevelIds) {
    const topLevelConcept = topLevelConceptsById.get(missingTopLevelId);
    treeItemsById.set(missingTopLevelId, topLevelConcept);

    for (const topLevelParentId of topLevelConcept.parentIds) {
      superTypes.push({ id: missingTopLevelId, parentId: topLevelParentId });
    }
  }

  if (missingTopLevelIds.length !== 0) {
    return attachTopLevels(topLevelConceptsById, treeItemsById, superTypes);
  }
};

const finalizeTree = nodes => {
  var tree = [],
  mappedArray = {},
  element,
  mappedElement;

  //First map the nodes of the array to an object -> create a hash table.
  for (var i = 0, len = nodes.length; i < len; i++) {
    element = nodes[i];
    if (mappedArray[element.concept.id] === undefined) {
      mappedArray[element.concept.id] = {
        concept: element.concept,
        parents: [ element.parent ]
      };
    } else {
      mappedArray[element.concept.id].parents.push(element.parent)
    }

    mappedArray[element.concept.id]["children"] = [];
  }

  for (var id in mappedArray) {
    if (mappedArray.hasOwnProperty(id)) {
      mappedElement = mappedArray[id];
      // If the element is not at the root level, add it to its parent array of children.
      if (mappedElement.parents.filter(p => p.id === ROOT_ID).length === 0) {
        for (var parent of mappedElement.parents) {
          if (mappedArray[parent.id]) {
            mappedArray[parent.id]["children"].push(mappedElement)
          }
        }
      }
      // If the element is at the root level, add it to first level elements array.
      else {
        tree.push(mappedElement);
      }
    }
  }
  return tree;
};

export { computeHierarchicalTree };
