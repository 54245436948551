import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { getIcon } from "../../../../../utils/iconContext";
import { parseEffectivetime } from "../../../../../utils/snomedUtils"
import Tooltip from "../../../../misc/Tooltip";
import {
  FULLY_SPECIFIED_NAME,
  SYNONYM,
  PREFFERED,
  ACCEPTABLE,
  LANGUAGE_REFSET_TYPE_US,
  LANGUAGE_REFSET_TYPE_GB
} from "../../../../../constants/SnomedConstants";

import {
  ID_ICON,
  EFFECTIVE_TIME_ICON,
  FULLY_SPECIFIED_NAME_ICON,
  SYNONYM_ICON,
  PREFERRED_ICON,
  DESCRIPTION_TYPE_ICON,
  LANG_CODE_ICON
} from  "../../../../../constants/ImageConstants";
 
const TYPE_RANK = (() => {
  const ranks = new Map()
  ranks.set(FULLY_SPECIFIED_NAME, 0)
  ranks.set(SYNONYM, 1)
  ranks.set(LANGUAGE_REFSET_TYPE_US, 0)
  ranks.set(LANGUAGE_REFSET_TYPE_GB, 1)
  ranks.set(PREFFERED, 0)
  ranks.set(ACCEPTABLE, 1)
  return ranks
})()

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  padding-bottom: 2px;
`

const Column = styled.div`
  display: flex;
  flex-basis: ${props => props.basis};
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${props => props.paddingBottom};
`

const ImagedTextContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${props => props.paddingBottom};
`;

const Text = styled.span`
  padding-left: 5px;
  font-weight: ${props => props.weight};
`

const ImagedText = ({ text, img, weight = "normal", showIcon = true, paddingBottom = "auto" }) => (
  <ImagedTextContainer paddingBottom={paddingBottom}>
    {showIcon ? <img src={getIcon(img)} alt=""/> : <span style={{height: "16px", width: "16px"}} />}
    <Text weight={weight}>{text}</Text>
  </ImagedTextContainer>
);

const TooltipContent = ({ desc }) => (
    <Box paddingBottom={0}>
      <Row>
        <Column>
          <ImagedText weight={600} text="ID:" img={ID_ICON}/>
          <Text>{desc.id}</Text>
        </Column>
      </Row>
      <Row>
        <Column>
          <ImagedText weight={600} text="Effective Time:" img={EFFECTIVE_TIME_ICON} />
          <Text>{parseEffectivetime(desc.effectiveTime)}</Text>
        </Column>
      </Row>
      <Row>
        <Column>
          <ImagedText weight={600} text="Language Code:" img={LANG_CODE_ICON} />
          <Text>{desc.languageCode}</Text>
        </Column>
      </Row>
      <Row>
        <Column>
          <ImagedText weight={600} text="Case significance:" img={desc.caseSignificance.iconId} />
          <Text>{desc.caseSignificance.pt.term}</Text>
        </Column>
      </Row>
      {window.config.snomed.showModulesInTooltips &&
        (
          <Row>
            <Column>
              <ImagedText weight={600} text="Module:" img={desc.module.iconId} />
              <Text>{desc.module.pt.term}</Text>
            </Column>
          </Row>
        )
      }
    </Box>
)

const DescriptionItems = ({langRefSetId, descriptions}) => {
  const langRefSetConcept = descriptions.map(desc => desc.acceptabilities.find(acc => acc.languageRefSetId === langRefSetId).languageRefSetConcept)[0]
  return (
    <Box paddingBottom="1em">
      <ImagedText text={langRefSetConcept.pt.term} img={langRefSetConcept.iconId} paddingBottom="0.25em" weight="bold" />
        {
          descriptions
          .sort((desc, otherDesc) => sortDescriptions(desc, otherDesc, langRefSetId))
          .map(desc => {
            return (
              <Row key={desc.id}>
                <Column basis="25%">
                  <ImagedText text={`${desc.type.pt.term}:`} img={getDescriptionIcon(desc.type.id)}/>
                </Column>
                <Column basis="75%">
                  <Tooltip title="Description" content={<TooltipContent desc={desc} />}>
                      <div>
                        <ImagedText text={desc.term} img={PREFERRED_ICON} showIcon={isPreferredSynonym(langRefSetId, desc)}/>
                      </div>
                  </Tooltip>
                </Column>
              </Row>
            )
          })
        }
    </Box>
  )
}


const Descriptions = ({ concept }) => {
  const groupedDescs = groupByLanguage(concept.descriptions.filter(desc => desc.active))
  return (
    <Container>
      {Object.keys(groupedDescs)
             .sort(sortByLanguageRefsets)
             .map(langRefSetId => <DescriptionItems key={langRefSetId} langRefSetId={langRefSetId} descriptions={groupedDescs[langRefSetId]}/>)
           }
    </Container>
  );
};


const isPreferredSynonym = (languageRefSetId, description) => {
  return description.acceptabilities.filter(value =>
    description.type.id === SYNONYM
    && value.languageRefSetId === languageRefSetId
    && value.acceptability.id === PREFFERED
  ).length > 0
}

const groupByLanguage = descriptions => {
  return descriptions.reduce((acc, desc) => {
    desc.acceptabilities.forEach(acceptability => {
      const group = acceptability.languageRefSetId;
      acc[group] = acc[group] || [];
      acc[group].push(desc);
    });
    return acc;
  }, {});
};

const sortByLanguageRefsets = (langRefSetId, otherLangRefSetId) => {
  const languageRank = getRank(langRefSetId)
  const otherLanguageRank = getRank(otherLangRefSetId)
  if (languageRank !== undefined && otherLanguageRank === undefined) {
    return -1;
  } else if (languageRank === undefined && otherLanguageRank === undefined) {
    return 0;
  } else if (languageRank === undefined && otherLanguageRank !== undefined) {
    return 1;
  } else {
    return (languageRank < otherLanguageRank) ? -1 : ((languageRank > otherLanguageRank) ? 1 : 0)
  }
}

const getRank = (typeId) => {
  return TYPE_RANK.get(typeId)
}

const sortDescriptions = (desc, otherDesc, langRefSetId) => {
  const sortedByTypeId = sortByTypeId(desc, otherDesc);
  if (sortedByTypeId === 0) {
    const sortedByAcceptability = sortByAcceptability(desc, otherDesc, langRefSetId);
    if (sortedByAcceptability === 0) {
      return sortByLabel(desc, otherDesc);
    } else {
      return sortedByAcceptability;
    }

  } else {
    return sortedByTypeId
  }
}

const sortByTypeId = (desc, otherDesc) => {
  const descRank = getRank(desc.type.id);
  const otherDescRank = getRank(otherDesc.type.id);
  if (descRank !== undefined && otherDescRank === undefined) {
    return -1;
  } else if (descRank === undefined && otherDescRank === undefined) {
    return 0;
  } else if (descRank === undefined && otherDesc !== undefined) {
    return 1;
  } else {
    return (descRank < otherDescRank) ? -1 : ((descRank > otherDescRank) ? 1 : 0);
  }
}

const sortByAcceptability = (desc, otherDesc, languageRefsetId) => {
  const acceptability = desc.acceptabilities.find(item => item.languageRefSetId === languageRefsetId);
  const otherAcceptability = otherDesc.acceptabilities.find(item => item.languageRefSetId === languageRefsetId);
  const acceptabilityRank = getRank(acceptability.acceptabilityId);
  const otherAcceptabilityRank = getRank(otherAcceptability.acceptabilityId);
  if (acceptabilityRank !== undefined && otherAcceptabilityRank === undefined) {
    return -1;
  } else if (acceptabilityRank === undefined && otherAcceptabilityRank === undefined) {
    return 0;
  } else if (acceptabilityRank === undefined && otherAcceptabilityRank !== undefined) {
    return 1;
  } else {
    return (acceptabilityRank < otherAcceptabilityRank) ? -1 : ((acceptabilityRank > otherAcceptabilityRank) ? 1 : 0);
  }
}

const sortByLabel = (desc, otherDesc) => {
  return desc.term.localeCompare(otherDesc.term);
}

const getDescriptionIcon = (typeId) => {
  switch (typeId) {
    case FULLY_SPECIFIED_NAME:
      return FULLY_SPECIFIED_NAME_ICON;
    case SYNONYM:
      return SYNONYM_ICON;
    default:
      return DESCRIPTION_TYPE_ICON;
  }
}

Descriptions.propTypes = {
  concept: PropTypes.object.isRequired
};

export default Descriptions;
