import React from "react";
import gql from "graphql-tag";
import styled from "styled-components";
import { getIcon } from "../../utils/iconContext";
import { Tree } from "antd";
import { Query } from "react-apollo";
import { ROOT_CONCEPT } from "../../constants/SnomedConstants";
import LoadingIndicator from "../misc/LoadingIndicator"

const LOAD_MORE_TITLE = "Load more..."
const TREE_ROOT = "-1"
const LIMIT = 100;

const CHILDREN_OF = gql`
  query getChildrenOf($branchPath: String, $parent: [String], $searchAfter: String, $acceptLanguage: String) {
    concepts(branchPath: $branchPath, parent: $parent, limit: ${LIMIT}, active:true, searchAfter: $searchAfter, sort:["term:asc"], acceptLanguage: $acceptLanguage) {
      items {
        id
        iconId
        module {
          id
          iconId
          pt {
            id
            term
          }
        }
        parentIds
        fsn {
          id
          term
        }
        pt {
          id
          term
        }
        descendants(direct: true, limit: 0) {
          total
        }
      }
      total
      searchAfter
    }
  }
`;

const Icon = styled.img`
  position: relative;
  float: left;
  display: inline;
  top: 4px;
`;

class BrowseTree extends React.Component {

  onNodeSelect = ({ node }, fetchMore) => {
    const { onTreeSelection } = this.props;
    const id = node.props.id
    if (isLoadMore(id)) {
      this.onLoadData({
        props: {
          ...node.props,
          id: node.props.parentId
        }
      }, fetchMore)
    } else {
      onTreeSelection(node.props.id);
    }
  };

  onLoadData = (node, fetchMore) => {
    const conceptId = node.props.id;
    if (node.props.fetchedChildren || isRootConcept(conceptId)) {
      return Promise.resolve();
    }
    const vars = {
      parent: [conceptId]
    }

    if ("searchAfter" in node.props) {
      vars["searchAfter"] = node.props.searchAfter
    }

    return fetchMore({
      variables: vars,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          concepts: {
            ...fetchMoreResult.concepts,
            items: [...prev.concepts.items, ...fetchMoreResult.concepts.items]
          }
        };
      }
    });
  };

  render() {
    return (
      <Query
        query={CHILDREN_OF}
        variables={{ branchPath: window.config.snomed.branchPath, parent: [ROOT_CONCEPT, TREE_ROOT], acceptLanguage: window.config.snomed.acceptableLanguages }}
      >
        {({ loading, error, data, fetchMore }) => {
          if (loading) {
            return (
              <LoadingIndicator
                iconStyles={{
                  color: window.config.theme.colors.primary,
                  fontSize: "20px"
                }}
                textStyles={{
                  fontSize: "14px",
                  fontWeight: 300,
                  padding: "0 5px"
                }}
              />
            )
          } else if (error) {
            this.props.onError(error)
            return <React.Fragment />
          } else {
            const concepts = data.concepts ? data.concepts.items : [];
            return (
              <Tree
                defaultExpandedKeys={[ROOT_CONCEPT]}
                showIcon
                onSelect={(keys, e) => this.onNodeSelect(e, fetchMore)}
                loadData={e => this.onLoadData(e, fetchMore)}
              >
                  {renderNodes(TREE_ROOT, concepts, data.concepts.searchAfter, this.props.withTooltip)}
              </Tree>
            );
          }
        }}
      </Query>
    );
  }
}


const renderNodes = (parentId, concepts, searchAfter, withTooltip) => {
  const nodes = concepts ? concepts : [];
  return nodes
    .filter(node => node.parentIds.includes(parentId))
    .map(child => {
      const id = child.id;
      const total = child.descendants.total;
      const isLeaf = total === 0;
      const numberOfExpectedChildren = total;
      const numberOfActualChildren = nodes.filter(node => node.parentIds.includes(id)).length
      const numberOfMissingChildren = numberOfExpectedChildren - numberOfActualChildren;
      const title = `${getConceptLabel(child)}`;
      const fetchedChildren = nodes.filter(node => node.parentIds.includes(id)).length > 0;
      const needsLoadMore = numberOfExpectedChildren > LIMIT && numberOfMissingChildren !== 0;

      return (
        <Tree.TreeNode
          id={id}
          key={id}
          isLeaf={isLeaf}
          parentId={parentId}
          fetchedChildren={fetchedChildren}
          title={withTooltip(title, child)}
          icon={<Icon src={getIcon(child.iconId)} />}
          style={{ padding: "0" }}
        >
          {!isLeaf && renderNodes(id, concepts, searchAfter, withTooltip)}
          {needsLoadMore && renderLoadMore(id, numberOfMissingChildren, searchAfter)}
        </Tree.TreeNode>
      );
    });
};

const renderLoadMore = (parentId, missingConceptsCount, searchAfter) => {
  return <Tree.TreeNode
    id={LOAD_MORE_TITLE}
    key={searchAfter}
    isLeaf={true}
    parentId={parentId}
    fetchedChildren={false}
    searchAfter={searchAfter}
    title={`${LOAD_MORE_TITLE} (${missingConceptsCount} concepts remain)`}
    style={{ padding: "0" }}
  />
}

const isLoadMore = id => LOAD_MORE_TITLE === id;

const isRootConcept = id => ROOT_CONCEPT === id;

const getConceptLabel = concept => {
  if (concept.pt) {
    return concept.pt.term;
  } else if (concept.fsn) {
    return concept.fsn.term;
  } else {
    return concept.id;
  }
};

export default BrowseTree;