import React from "react";
import gql from "graphql-tag";
import { Tabs } from "antd";
import styled from "styled-components";
import WelcomaPage from "./pages/WelcomePage";
import { withRouter } from "react-router";
import queryString from "query-string";
import { Query } from "react-apollo";
import OverviewPage from "./pages/overview/OverviewPage"
import LoadingIndicator from "../misc/LoadingIndicator"
import MembershipPage from "./pages/membership/MembershipPage";
import ErrorComponent from "../misc/ErrorComponent"

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`;

const EditorContainer = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

const QUERY = gql`
  query editorConcept($branchPath: String, $ecl: String, $acceptLanguage: String) {
    concepts(branchPath: $branchPath, acceptLanguage: $acceptLanguage, ecl: $ecl, limit: 1000) {
      items {
        id
        iconId
        pt {
          id
          term
        }
        fsn {
          id
          term
        }
        module {
          id
          iconId
          pt {
            id
            term
          }
        }
        effectiveTime
        active
        definitionStatus {
          id
          pt {
            id
            term
          }
        }
        subclassDefinitionStatus
        descriptions {
          id
          active
          term
          effectiveTime
          languageCode
          caseSignificance {
            id
            iconId
            pt {
              id
              term
            }
          }
          module {
            id
            iconId
            pt {
              id
              term
            }
          }
          type {
            id
            fsn {
              id
              term
            }
            pt {
              id
              term
            }
          }
          acceptabilities {
            acceptabilityId
            languageRefSetId
            languageRefSetConcept {
              iconId
              id
              pt {
                id
                term
              }
            }
            acceptability {
              id
              pt {
                id
                term
              }
            }
          }
        }
        members {
          id
          referenceSetId
          query
          referenceSetConcept {
            id
            type {
              id
            }
            iconId
            pt {
              id
              term
            }
          }
          active
          effectiveTime
          mapTargetId
          module {
            id
            iconId
            pt {
              id
              term
            }
          }
          referencedComponent {
            id
            iconId
            pt {
              id
              term
            }
          }
          targetComponent {
            id
            iconId
            pt {
              id
              term
            }
          }
          value {
            id
            iconId
            pt {
              id
              term
            }
          }
        }
        relationships {
          id
          active
          module {
            iconId
            pt {
              id
              term
            }
          }
          effectiveTime
          source {
            id
          }
          modifier {
            id
            iconId
            pt {
              id
              term
            }
          }
          type {
            id
            iconId
            fsn {
              id
              term
            }
            pt {
              id
              term
            }
          }
          destination {
            id
            iconId
            fsn {
              id
              term
            }
            pt {
              id
              term
            }
          }
          value {
            type
            stringValue
            numericValue
          }
          characteristicType {
            id
            iconId
            pt {
              id
              term
            }
          }
          group
        }
      }
    }
  }
`;

class Editor extends React.Component {

  getSelectedComponentId = () => {
    const { location } = this.props;
    const searchParams = queryString.parse(location.search)
    if ("component" in searchParams) {
      return searchParams["component"]
    } else {
      return undefined
    }
  }

  render() {
    const selectedComponentId = this.getSelectedComponentId()
    if (selectedComponentId) {
      return (
        <Query
          query={QUERY}
          variables={{ branchPath: window.config.snomed.branchPath, ecl: selectedComponentId, acceptLanguage: window.config.snomed.acceptableLanguages }}
        >
          {({ loading, error, data }) => {
            if (loading) {
              return (
                <Container>
                  <LoadingIndicator
                    centered={true}
                    iconStyles={{
                      color: window.config.theme.colors.primary,
                      fontSize: "60px"
                    }}
                    textStyles={{
                      fontSize: "16px",
                      padding: "10px 0",
                      fontWeight: 400
                    }}
                  />
                </Container>
              )
            } else if (error) {
              return <ErrorComponent errorMessage={error.message}/>;
            } else {
              const concept = data.concepts ? data.concepts.items[0] : undefined
              if (concept) {
                return (
                  <Container>
                    <EditorContainer>
                      <Tabs size="small" >
                        <Tabs.TabPane key="1" tab="Overview">
                          <OverviewPage concept={concept} {...this.props}/>
                        </Tabs.TabPane>
                        <Tabs.TabPane key="2" tab="Membership">
                          <MembershipPage members={concept.members} {...this.props}/>
                        </Tabs.TabPane>
                      </Tabs>
                    </EditorContainer>
                  </Container>
                )
              } else {
                // render 404
              }
            }
          }}
        </Query>
      )
    } else {
      return (
        <Container>
          <WelcomaPage />
        </Container>
      )
    }
  }
}

export default withRouter(Editor)