import React from "react";
import gql from "graphql-tag";
import styled from "styled-components";
import { ROOT_CONCEPT } from "../../constants/SnomedConstants";
import { Query } from "react-apollo";
import { computeHierarchicalTree } from "./utils/TreeUtils";
import { Tree } from "antd";
import { getIcon } from "../../utils/iconContext";
import LoadingIndicator from "../misc/LoadingIndicator"

const NO_MATCHING_COMPONENTS_LABEL = "No matching components found.";

const SEARCH_QUERY = gql`
  query search($branchPath: String, $term: String, $ecl: String, $mapTarget: String, $acceptLanguage: String) {
    concepts(branchPath: $branchPath, term: $term, ecl: $ecl, mapTarget: $mapTarget, limit: 100000, active: true, acceptLanguage: $acceptLanguage) {
      items {
        id
        iconId
        parentIds
        ancestorIds
        module {
          id
          iconId
          pt {
            id
            term
          }
        }
        fsn {
          id
          term
        }
        pt {
          id
          term
        }
      }
      total
    }
  }
`;

const TOP_LEVEL_NODES = gql`
  query ROOT_CONCEPTS_QUERY($branchPath: String, $ecl: String, $acceptLanguage: String) {
    concepts(branchPath: $branchPath, ecl: $ecl, limit: 100, acceptLanguage: $acceptLanguage) {
      items {
        id
        iconId
        module {
          id
          iconId
          pt {
            id
            term
          }
        }
        pt {
          id
          term
        }
        fsn {
          id
          term
        }
        parentIds
      }
    }
  }
`;

const ImagedTextContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-left: 20px;
`;

const Text = styled.span`
  padding-left: 5px;
  font-weight: ${props => props.weight};
`

const ImagedText = ({ text, img, weight = "normal", showIcon = true}) => (
  <ImagedTextContainer>
    {showIcon ? <img src={getIcon(img)} alt=""/> : <span style={{height: "16px", width: "16px"}} />}
    <Text weight={weight}>{text}</Text>
  </ImagedTextContainer>
);

const Icon = styled.img`
  position: relative;
  float: left;
  display: inline;
  top: 4px;
`;

class FilteredTree extends React.PureComponent {

  onSelect = (selectedKeys, { node }) => {
    const { onTreeSelection } = this.props;
    onTreeSelection(node.props.id);
  };

  render() {
    const { filterText } = this.props;
    const refsetFilter = window.config.snomed.refsetFilter

    let vars = {
      branchPath: window.config.snomed.branchPath,
      ecl: refsetFilter ? refsetFilter : undefined,
      term: filterText && !filterText.endsWith("D") ? filterText : undefined,
      mapTarget: filterText && filterText.endsWith("D") ? filterText : undefined,
      acceptLanguage: window.config.snomed.acceptableLanguages
    }

    return (
      <Query
        query={TOP_LEVEL_NODES}
        variables={{ branchPath: window.config.snomed.branchPath, ecl: `<!${ROOT_CONCEPT} OR ${ROOT_CONCEPT}`, acceptLanguage: window.config.snomed.acceptableLanguages }}
      >
        {({ loading: topLevelsLoading, data: topLevelNodes, error: firstQueryError }) => (
          <Query query={SEARCH_QUERY} variables={vars}>
            {({ loading: searchResultsLoading, data: searchResults, error: secondQueryError }) => {
              if (topLevelsLoading || searchResultsLoading) {
                return (
                  <LoadingIndicator
                    iconStyles={{
                      color: window.config.theme.colors.primary,
                      fontSize: "20px"
                    }}
                    textStyles={{
                      fontSize: "14px",
                      fontWeight: 300,
                      padding: "0 5px"
                    }}
                  />
                )
              } else if (firstQueryError) {
                this.props.onError(firstQueryError)
                return <React.Fragment/>
              } else if (secondQueryError) {
                this.props.onError(secondQueryError)
                return <React.Fragment/>
              } else {
                const topLevelConcepts = topLevelNodes ? topLevelNodes.concepts.items : [];
                const matches = searchResults ? searchResults.concepts.items : [];

                if (matches.length === 0) {
                  return <ImagedText text={NO_MATCHING_COMPONENTS_LABEL} img={"exclamation"}/>
                } else {
                  const treeNodesToRender = computeHierarchicalTree(topLevelConcepts, matches);
                  return (
                    <Tree
                      defaultExpandedKeys={[ROOT_CONCEPT]}
                      defaultExpandAll={!!filterText}
                      showIcon
                      onSelect={this.onSelect}
                    >
                      {renderTreeNodes(treeNodesToRender, this.props.withTooltip)}
                    </Tree>
                  );
                }
              }
            }}
          </Query>
        )}
      </Query>
    );
  }
}

const renderTreeNodes = (nodes, withTooltip, parentPath = []) => {
  return nodes
    .sort((n1, n2) => {
      const conceptLabel = getConceptLabel(n1.concept);
      const otherConceptLabel = getConceptLabel(n2.concept);
      return conceptLabel.localeCompare(otherConceptLabel);
    })
    .map(node => {
      const concept = node.concept;
      const isLeaf = node.children ? node.children.length === 0 : true;
      const title = getConceptLabel(concept);
      const nodeKey = concept.id === ROOT_CONCEPT ? concept.id : `${concept.id}-${node.children.length}`
      const path = [...parentPath, nodeKey]
      return (
        <Tree.TreeNode
          id={concept.id}
          key={path.join("/")}
          style={{ padding: "0" }}
          title={withTooltip(title, concept)}
          isLeaf={isLeaf}
          icon={<Icon src={getIcon(concept.iconId)} />}
        >
          {!isLeaf && renderTreeNodes(node.children, withTooltip, path)}
        </Tree.TreeNode>
      );
    });
};

const getConceptLabel = concept => {
  if (concept.pt) {
    return concept.pt.term;
  } else if (concept.fsn) {
    return concept.fsn.term;
  } else {
    return concept.id;
  }
};

export default FilteredTree;
