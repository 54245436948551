const { QUESTION_ICON } = require("../constants/ImageConstants")

const getIcon = iconId => {
  let icon = null
  try {
    icon = require(`../assets/images/snomed/${iconId}.png`)
  } catch (error) {
    icon = require(`../assets/images/snomed/${QUESTION_ICON}.png`)
  }
  return icon
};

export { getIcon };