import React from "react";
import styled from "styled-components";
import { Icon } from "antd"

const Container = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    /* flex: 1; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Code = styled.code`
    background-color: lightgray;
    padding: 5px;
`

const Header = styled.h1`
    margin: 0;
`

const Scrollable = styled.div`
    overflow: auto;
`

export default ({ errorMessage }) => {
    return (
        <Container>
            <Icon type="frown" style={{fontSize: "88px"}}/>
            <Header>Oops!</Header>
            <h3>Something went wrong during your request.</h3>
            <Scrollable>
                <Code>Reason: {errorMessage}</Code>
            </Scrollable>
        </Container>
    )
}