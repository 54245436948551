import React from "react";
import styled from "styled-components";

const Container = styled.div`
  margin-top: 10px;
  margin-left: 10px;
`

export default () => (
  <Container>
    <h3>Welcome to {window.config.brand.title}&reg;</h3>
    <p>Some tips:</p>
    <ul>
      <li>
        <span>If you "<strong>Type to filter concepts</strong>" then the tree will display only matching concepts in a tree form</span>
      </li>
      <li>
        <span><strong>Click</strong> a concept to view its details.</span>
      </li>
    </ul>
    <p>Pro tips:</p>
    <ul>
      {window.config.tips.map(tip => <li dangerouslySetInnerHTML={{__html: tip}} key={tip} />)}
    </ul>
  </Container>
);
