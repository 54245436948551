import "core-js";
import "regenerator-runtime/runtime";

// load configuration object based on path
const loadConfig = (path, callback) => {
  var xobj = new XMLHttpRequest();
  xobj.overrideMimeType("application/json");
  xobj.open('GET', path, true);
  xobj.setRequestHeader('Cache-Control', 'no-cache');
  xobj.onreadystatechange = () => {
    if ((xobj.readyState === 3 && xobj.status === 200 && xobj.responseText)
      || (xobj.readyState === 4 && xobj.status === 200)) {
      // Required use of an anonymous callback as .open will NOT return a value but simply returns undefined in asynchronous mode
      callback(JSON.parse(xobj.responseText));
    }
  };
  xobj.send(null);  
};

const configId = window.location.pathname

loadConfig(`${process.env.PUBLIC_URL}/static${configId}/config.json`, (config) => {
  if (configId) {
    loadConfig(`${process.env.PUBLIC_URL}/static${configId}/config.json`, (slugConfig) => {
      window.config = Object.assign({}, config, slugConfig);
      require("./init.js");
    });
  } else {
    window.config = config
    require("./init.js");
  }
})
