import React from "react";
import styled from "styled-components";
import { getIcon } from "../../../../../utils/iconContext";
import { Link } from "react-router-dom";
import queryString from "query-string";
import {
  REFSET_CONCEPT_INACTIVITY_INDICATOR,
  REFSET_HISTORICAL_ASSOCIATION,
  REFSET_ALTERNATIVE_ASSOCIATION,
  REFSET_MOVED_FROM_ASSOCIATION,
  REFSET_MOVED_TO_ASSOCIATION,
  REFSET_POSSIBLY_EQUIVALENT_TO_ASSOCIATION,
  REFSET_REFERS_TO_ASSOCIATION,
  REFSET_REPLACED_BY_ASSOCIATION,
  REFSET_SAME_AS_ASSOCIATION,
  REFSET_SIMILAR_TO_ASSOCIATION,
  REFSET_WAS_A_ASSOCIATION
} from "../../../../../constants/SnomedConstants";

import {
  INACTIVE_ICON,
  ACTIVE_ICON,
  EFFECTIVE_TIME_ICON,
  DEFINED_ICON,
  PRIMITIVE_ICON,
  NON_DISJOINT_ICON,
  MUTUALLY_DISJOINT_ICON
} from "../../../../../constants/ImageConstants";

const ASSOCIATION_REFSETS_BY_ID = (() => {
  const map = new Map();
  map.set(REFSET_HISTORICAL_ASSOCIATION, "Historical concept");
  map.set(REFSET_ALTERNATIVE_ASSOCIATION, "Alternative concept");
  map.set(REFSET_MOVED_FROM_ASSOCIATION, "Moved from");
  map.set(REFSET_MOVED_TO_ASSOCIATION, "Moved to");
  map.set(REFSET_POSSIBLY_EQUIVALENT_TO_ASSOCIATION, "Possibly equivalent to");
  map.set(REFSET_REFERS_TO_ASSOCIATION, "Refers to");
  map.set(REFSET_REPLACED_BY_ASSOCIATION, "Replaced by");
  map.set(REFSET_SAME_AS_ASSOCIATION, "Same as");
  map.set(REFSET_SIMILAR_TO_ASSOCIATION, "Similar to");
  map.set(REFSET_WAS_A_ASSOCIATION, "Was a");
  return map;
})();

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 1em;
`;

const Box = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-basis: ${props => props.basis};
  flex-direction: column;
  flex: 1 auto auto;
`;

const ImagedTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.span`
  padding-left: 5px;
`;

const ImagedText = ({ text, icon }) => (
  <ImagedTextContainer>
    <img src={getIcon(icon)} alt="" />
    <Text>{text}</Text>
  </ImagedTextContainer>
);

const ImagedLink = ({ id, text, icon, location }) => (
  <ImagedTextContainer>
    <img src={getIcon(icon)} alt="" />
    <Link to={getRedirectObject(id, location)} style={{ paddingLeft: "5px" }}>
      {text}
    </Link>
  </ImagedTextContainer>
);

const InactivationProperties = ({ members }) => {
  return members
    .filter(
      member => REFSET_CONCEPT_INACTIVITY_INDICATOR === member.referenceSetId
    )
    .map(member => {
      return (
        <React.Fragment key={member.id}>
          <Box basis="25%">
            <span>Inactivation indicator:</span>
          </Box>
          <Box basis="75%">
            <ImagedText
              text={member.value.pt.term}
              icon={member.value.iconId}
            />
          </Box>
        </React.Fragment>
      );
    });
};

const AssociationTargetProperties = ({ members, location }) => {
  return members
    .filter(member => ASSOCIATION_REFSETS_BY_ID.has(member.referenceSetId))
    .map(member => {
      return (
        <React.Fragment key={member.id}>
          <Box basis="25%">
            <span>{ASSOCIATION_REFSETS_BY_ID.get(member.referenceSetId)}:</span>
          </Box>
          <Box basis="75%">
            <ImagedLink
              id={member.targetComponent.id}
              text={member.targetComponent.pt.term}
              icon={member.targetComponent.iconId}
              location={location}
            />
          </Box>
        </React.Fragment>
      );
    });
};

const SnomedCTProperties = ({ concept, ...rest }) => {
  const {
    definitionStatus,
    subclassDefinitionStatus,
    members,
    effectiveTime,
    module
  } = concept;

  return (
    <Container>
      <Box basis="25%">
        <span>Concept ID:</span>
        <span>Module:</span>
        <span>Effective Time:</span>
        <span>Status:</span>
        <span>Definition status:</span>
        <span>Subclass definitions:</span>
      </Box>
      <Box basis="75%">
        <ImagedText text={concept.id} icon={concept.iconId} />
        <ImagedLink
          id={module.id}
          text={module.pt.term}
          icon={module.iconId}
          location={rest.location}
        />
        <ImagedText
          text={parseEffectiveTime(effectiveTime)}
          icon={EFFECTIVE_TIME_ICON}
        />
        <ImagedText text={parseStatus(concept)} icon={getStatusIcon(concept)} />
        <ImagedText
          text={definitionStatus.pt.term}
          icon={getDefininitonStatusIcon(definitionStatus)}
        />
        <ImagedText
          text={parseSubClassDefinition(subclassDefinitionStatus)}
          icon={getSubclassIcon(subclassDefinitionStatus)}
        />
      </Box>
      {!concept.active && <InactivationProperties members={members} />}
      {!concept.active && (
        <AssociationTargetProperties
          members={members}
          location={rest.location}
        />
      )}
    </Container>
  );
};

const parseEffectiveTime = effectiveTime => {
  if (effectiveTime === null || effectiveTime === undefined) {
    return "Unpublished";
  } else {
    const year = effectiveTime.slice(0, 4);
    const month = effectiveTime.slice(4, 6);
    const days = effectiveTime.slice(6, 8);
    return [year, month, days].join("-");
  }
};

const parseStatus = concept => {
  return concept.active ? "Active" : "Inactive";
};

const getStatusIcon = concept => {
  return concept.active ? ACTIVE_ICON : INACTIVE_ICON;
};

const getDefininitonStatusIcon = definitionStatus => {
  return definitionStatus.pt.term === "Defined" ? DEFINED_ICON : PRIMITIVE_ICON;
};

const parseSubClassDefinition = subclassDefinition => {
  return subclassDefinition === "NON_DISJOINT_SUBCLASSES"
    ? "Non-disjoint"
    : "Mutually disjoint";
};

const getSubclassIcon = subclassDefinitionStatus => {
  return subclassDefinitionStatus === "NON_DISJOINT_SUBCLASSES"
    ? NON_DISJOINT_ICON
    : MUTUALLY_DISJOINT_ICON;
};

const getRedirectObject = (id, location) => {
  const existingSearchParams = queryString.parse(location.search);
  const newSearchParam = Object.assign(existingSearchParams, {
    component: id
  });
  return {
    pathname: location.pathname,
    search: queryString.stringify(newSearchParam)
  };
};

export default SnomedCTProperties;