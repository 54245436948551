import "antd/dist/antd.css";
import { Helmet } from 'react-helmet';
import GlobalStyle from "../styles/theme/globalStyle";
import apolloClient from "../utils/ApolloClient";
import React, { Fragment } from "react";
import { ApolloProvider } from "react-apollo";
import { Router, Route, Link } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Sidebar from "react-sidebar"
import {
  Container,
  Header,
  Icon,
  Content,
  CopyrightNotice,
  Footer,
  HamburgerIcon
} from "./layout/DefaultLayout";
import { createBrowserHistory } from "history";
import { withCookies } from "react-cookie";
import LicenseModal from "./misc/LicenseModal";
import CookieBanner from "./misc/CookieBanner";
import ReactGA from "react-ga";
import queryString from "query-string";
import Browse from "./pages/Browse"
import Search from "./pages/Search"
import { Menu, MenuItem, SubMenu } from "./misc/Menu"

const sidebarStyle = {
  sidebar: {
    width: "15rem",
    background: "#303030",
    top: "55px",
    bottom: "60px",
    borderRight: `solid 5px ${window.config.theme.colors.primary}`
  }
}

const SidebarContent = ({ location, viewId, closeSiderBar }) => {
  const viewIds = ["browse", "search"]
  return (
    <Menu defaultSelectedId={viewId} onSelect={closeSiderBar}>
      <SubMenu
        id="terminology"
        title={<span style={{ lineHeight: "2.5", padding: "15px" }}>TERMINOLOGY</span>}
      >
        {
          viewIds.map(viewId => (
            <MenuItem key={viewId} id={viewId}>
              <span>
                <Link
                  style={{ all: "inherit" }}
                  to={{ pathname: location.pathname, search: queryString.stringify({ view: viewId }) }}
                >
                  {viewId.toUpperCase()}
                </Link>
              </span>
            </MenuItem>
          ))
        }
      </SubMenu>
    </Menu>
  )
}

const history = createBrowserHistory();

history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

class App extends React.Component {
  state = {
    acceptedLicense: this.props.cookies.get("acceptedLicense"),
    acceptedCookies: this.props.cookies.get("acceptedCookies"),
    sidebarOpen: false,
  };

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
  }

  onLicenseAcceptance = () => {
    const { cookies } = this.props;

    var expireData = new Date();
    // expire after one day
    expireData.setDate(expireData.getDate() + 1);

    this.setState({ acceptedLicense: "true" });
    cookies.set("acceptedLicense", "true", {
      path: "/",
      expires: expireData
    });
  };

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open });
  }

  closeSiderBar = () => {
    this.setState({ sidebarOpen: false });
  }

  onLicenseRejection = () => {
    const { cookies } = this.props;
    this.setState({ acceptedLicense: "false" });
    cookies.remove("acceptedLicense");
  };

  onCookieAcceptance = () => {
    const { cookies } = this.props;
    var expireData = new Date();
    // expire after one day
    expireData.setDate(expireData.getDate() + 1);

    this.setState({ acceptedCookies: "true" });
    cookies.set("acceptedCookies", "true", {
      path: "/",
      expires: expireData
    });
  };

  isLicenseAccepted = () => {
    return this.state.acceptedLicense === undefined
      ? false
      : JSON.parse(this.state.acceptedLicense);
  };

  shouldDisplayCookieBanner = () => {
    return this.isLicenseAccepted() && window.config.commons.cookieConsent &&  this.state.acceptedCookies === undefined;
  };

  getViewParameter = (search) => {
    const searchParameters = queryString.parse(search);
    return searchParameters.view ? searchParameters.view : "browse";
  }

  render() {
    return (
      <Fragment>
        {!this.isLicenseAccepted() && window.config.commons.licenseDialog && (
          <LicenseModal
            visible={!this.isLicenseAccepted()}
            onAccept={this.onLicenseAcceptance}
            onReject={this.onLicenseReject}
          />
        )}
        <Helmet>
          <title>{window.config.brand.title}</title>
          <link rel="shortcut icon" href={window.config.brand.favico} />
        </Helmet>
        <GlobalStyle />
        <Router history={history}>
          <ApolloProvider client={apolloClient}>
            <ThemeProvider theme={window.config.theme}>
              <Sidebar
                sidebar={<SidebarContent location={history.location} viewId={this.getViewParameter(history.location.search)} closeSiderBar={this.closeSiderBar} />}
                open={this.state.sidebarOpen}
                onSetOpen={this.onSetSidebarOpen}
                styles={sidebarStyle}
              >
                <Container>
                  <Header>
                    {window.config.commons.sidebar &&
                      <HamburgerIcon onClick={e => this.onSetSidebarOpen(true)} />
                    }
                    <Icon />
                  </Header>
                  <Content>
                    <Route
                      path="/"
                      render={(props) => {
                        if (this.getViewParameter(props.history.location.search) === "browse") {
                          return <Browse />
                        } else {
                          return <Search />
                        }
                      }}
                    />
                  </Content>
                  <Footer>
                    <CopyrightNotice />
                    {this.shouldDisplayCookieBanner() && (
                      <CookieBanner onAccept={this.onCookieAcceptance} />
                    )}
                  </Footer>
                </Container>
              </Sidebar>
            </ThemeProvider>
          </ApolloProvider>
        </Router>
      </Fragment>
    );
  }
}

export default withCookies(App);
