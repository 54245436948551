import "react-splitter-layout/lib/index.css";
import React from "react";
import styled from "styled-components";
import SplitterLayout from "react-splitter-layout";
import PropTypes from "prop-types";

const Pane = styled.div`
  position: relative;
  height: 98%;
  background-color: #f5f5f5;
  border-radius: 0px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  margin: 10px 10px 0 10px;
`
const Splitter = ({ left, right, ...rest }) => {
  return (
    <SplitterLayout percentage primaryIndex={0} secondaryInitialSize={70}>
      <Pane>
        {React.cloneElement(left, rest)}
      </Pane>
      <Pane>
        {React.cloneElement(right, rest)}
      </Pane>
    </SplitterLayout>
  );
};

Splitter.propTypes = {
  left: PropTypes.element.isRequired,
  right: PropTypes.element.isRequired
};

export default Splitter;
