const ROOT_CONCEPT = "138875005";
const FULLY_SPECIFIED_NAME = "900000000000003001";
const SYNONYM = "900000000000013009";
const PREFFERED = "900000000000548007";
const ACCEPTABLE = "900000000000549004";

//Relationship types
const IS_A = "116680003";

//Characteristic types
const DEFINING_RELATIONSHIP = "900000000000006009";
const QUALIFYING_RELATIONSHIP = "900000000000225001";
const INFERRED_RELATIONSHIP = "900000000000011006";
const STATED_RELATIONSHIP = "900000000000010007";
const ADDITIONAL_RELATIONSHIP = "900000000000227009";

//ref sets
const SIMPLE_TYPE_REFSET_ID = "446609009";
const SIMPLE_MAP_REFSET_ID = "900000000000496009";
const LANGUAGE_REFSET_ID = "900000000000506000";
const QUERY_REFSET_ID = "900000000000512005";
const ATTRIBUTE_VALUE = "900000000000480006";
const COMPLEX_MAP_REFSET_ID = "447250001";
const DESCRIPTION_TYPE_REFSET_ID = "900000000000538005";
const CONCRETE_DATA_TYPE_REFSET_ID = "";
const ASSOCIATION_REFSET_ID = "900000000000521006";
const MODULE_DEPENDENCY_REFSET_ID = "900000000000534007";
const EXTENDED_MAP_REFSET_ID = "609331003";
const SIMPLE_MAP_WITH_DESCRIPTION_REFSET_ID = "";
const OWL_AXIOM_REFSET_ID = "733073007";
const OWL_ONTOLOGY_REFSET_ID = "762103008";
const MRCM_DOMAIN_REFSET_ID = "723589008";
const MRCM_ATTRIBUTE_DOMAIN_REFSET_ID = "723604009";
const MRCM_ATTRIBUTE_RANGE_REFSET_ID = "723592007";
const MRCM_MODULE_REFSET_ID = "723563008";
const ANNOTAION_REFSET_ID = "900000000000516008";

const LANGUAGE_REFSET_TYPE_US = "900000000000509007";
const LANGUAGE_REFSET_TYPE_GB = "900000000000508004";

// Inactivation indicator reference sets
const REFSET_CONCEPT_INACTIVITY_INDICATOR = "900000000000489007";

// Historical reference sets
const REFSET_HISTORICAL_ASSOCIATION = "900000000000522004";
const REFSET_ALTERNATIVE_ASSOCIATION = "900000000000530003";
const REFSET_MOVED_FROM_ASSOCIATION = "900000000000525002";
const REFSET_MOVED_TO_ASSOCIATION = "900000000000524003";
const REFSET_POSSIBLY_EQUIVALENT_TO_ASSOCIATION = "900000000000523009";
const REFSET_REFERS_TO_ASSOCIATION = "900000000000531004";
const REFSET_REPLACED_BY_ASSOCIATION = "900000000000526001";
const REFSET_SAME_AS_ASSOCIATION = "900000000000527005";
const REFSET_SIMILAR_TO_ASSOCIATION = "900000000000529008";
const REFSET_WAS_A_ASSOCIATION = "900000000000528000";

export {
  ROOT_CONCEPT,
  FULLY_SPECIFIED_NAME,
  SYNONYM,
  PREFFERED,
  ACCEPTABLE,
  LANGUAGE_REFSET_TYPE_US,
  LANGUAGE_REFSET_TYPE_GB,
  REFSET_CONCEPT_INACTIVITY_INDICATOR,
  REFSET_HISTORICAL_ASSOCIATION,
  REFSET_ALTERNATIVE_ASSOCIATION,
  REFSET_MOVED_FROM_ASSOCIATION,
  REFSET_MOVED_TO_ASSOCIATION,
  REFSET_POSSIBLY_EQUIVALENT_TO_ASSOCIATION,
  REFSET_REFERS_TO_ASSOCIATION,
  REFSET_REPLACED_BY_ASSOCIATION,
  REFSET_SAME_AS_ASSOCIATION,
  REFSET_SIMILAR_TO_ASSOCIATION,
  REFSET_WAS_A_ASSOCIATION,
  DEFINING_RELATIONSHIP,
  STATED_RELATIONSHIP,
  INFERRED_RELATIONSHIP,
  QUALIFYING_RELATIONSHIP,
  ADDITIONAL_RELATIONSHIP,
  IS_A,
  SIMPLE_TYPE_REFSET_ID,
  SIMPLE_MAP_REFSET_ID,
  LANGUAGE_REFSET_ID,
  QUERY_REFSET_ID,
  ATTRIBUTE_VALUE,
  COMPLEX_MAP_REFSET_ID,
  DESCRIPTION_TYPE_REFSET_ID,
  CONCRETE_DATA_TYPE_REFSET_ID,
  ASSOCIATION_REFSET_ID,
  MODULE_DEPENDENCY_REFSET_ID,
  EXTENDED_MAP_REFSET_ID,
  SIMPLE_MAP_WITH_DESCRIPTION_REFSET_ID,
  OWL_AXIOM_REFSET_ID,
  OWL_ONTOLOGY_REFSET_ID,
  MRCM_DOMAIN_REFSET_ID,
  MRCM_ATTRIBUTE_DOMAIN_REFSET_ID,
  MRCM_ATTRIBUTE_RANGE_REFSET_ID,
  MRCM_MODULE_REFSET_ID,
  ANNOTAION_REFSET_ID
};
